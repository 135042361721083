import { SelectHTMLAttributes } from "react";
import { UseFormRegister, FieldError, FieldValues } from "react-hook-form";
import { Callback } from "webpack-cli";

type OptionType = {
  value: string;
  label: string;
};

interface SelectElementProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options: Array<OptionType>;
  label?: string;
  labelClasses?: string;
  register: UseFormRegister<any>;
  rules: FieldValues;
  error?: FieldError;
}

export function SelectElement({
  options,
  label,
  labelClasses,
  register,
  rules,
  error,
  ...props
}: SelectElementProps) {
  const { name, id } = props;

  return (
    <div className="mt-6">
      {label && (
        <label
          htmlFor={id}
          className={`block text-primary text-sm font-medium font-montserrat leading-6 ${labelClasses}`}
        >
          {label}
        </label>
      )}
      <select
        {...props}
        {...register(name, rules)}
        className="mt-2 mb-1 bg-transparent active:bg-transparent focus:bg-transparent block w-full rounded-xl	outline outline-2 outline-primary border-r-[12px] border-transparent px-4 py-3 font-montserrat text-lg text-white placeholder:text-white sm:text-sm sm:leading-6"
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && (
        <p className="text-white text-xs font-montserrat mt-1">
          {error.message}
        </p>
      )}
    </div>
  );
}
