export function EyeIconShow() {
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3613 5.7692C17.3365 5.71324 16.7365 4.38228 15.4027 3.04849C13.6255 1.27128 11.3808 0.332031 8.91016 0.332031C6.43949 0.332031 4.19478 1.27128 2.41757 3.04849C1.08377 4.38228 0.480983 5.71537 0.459025 5.7692C0.426805 5.84167 0.410156 5.9201 0.410156 5.99941C0.410156 6.07872 0.426805 6.15715 0.459025 6.22962C0.483816 6.28558 1.08377 7.61583 2.41757 8.94962C4.19478 10.7261 6.43949 11.6654 8.91016 11.6654C11.3808 11.6654 13.6255 10.7261 15.4027 8.94962C16.7365 7.61583 17.3365 6.28558 17.3613 6.22962C17.3935 6.15715 17.4102 6.07872 17.4102 5.99941C17.4102 5.9201 17.3935 5.84167 17.3613 5.7692ZM8.91016 10.532C6.7299 10.532 4.82519 9.73941 3.24844 8.17683C2.60148 7.53344 2.05107 6.79979 1.61432 5.9987C2.05095 5.19753 2.60138 4.46387 3.24844 3.82058C4.82519 2.25799 6.7299 1.46537 8.91016 1.46537C11.0904 1.46537 12.9951 2.25799 14.5719 3.82058C15.2201 4.46371 15.7717 5.19738 16.2095 5.9987C15.6988 6.95212 13.474 10.532 8.91016 10.532ZM8.91016 2.5987C8.2377 2.5987 7.58034 2.79811 7.02122 3.1717C6.46209 3.5453 6.0263 4.07631 5.76896 4.69758C5.51162 5.31885 5.44429 6.00247 5.57548 6.66201C5.70667 7.32155 6.03049 7.92737 6.50599 8.40287C6.98149 8.87837 7.58731 9.20218 8.24685 9.33337C8.90638 9.46456 9.59001 9.39723 10.2113 9.1399C10.8326 8.88256 11.3636 8.44677 11.7372 7.88764C12.1108 7.32852 12.3102 6.67116 12.3102 5.9987C12.3092 5.09725 11.9507 4.23299 11.3133 3.59557C10.6759 2.95815 9.8116 2.59964 8.91016 2.5987ZM8.91016 8.26537C8.46185 8.26537 8.02361 8.13243 7.65086 7.88337C7.27811 7.6343 6.98759 7.2803 6.81603 6.86612C6.64447 6.45194 6.59958 5.99619 6.68704 5.5565C6.7745 5.11681 6.99038 4.71292 7.30738 4.39593C7.62438 4.07893 8.02826 3.86305 8.46795 3.77559C8.90764 3.68813 9.36339 3.73301 9.77757 3.90457C10.1918 4.07613 10.5458 4.36666 10.7948 4.73941C11.0439 5.11216 11.1768 5.5504 11.1768 5.9987C11.1768 6.59986 10.938 7.1764 10.5129 7.60148C10.0878 8.02656 9.51131 8.26537 8.91016 8.26537Z"
        fill="white"
      />
    </svg>
  );
}
