import { useEffect } from "react";
import { CircularProgress } from "@mui/material";

export function Authenticate() {
  useEffect(() => {
    // Parse the ID token from the query string
    const queryParams = new URLSearchParams(location.hash.replace("#", "?"));
    const idToken = queryParams.get("id_token");

    // Save the ID token to local storage
    localStorage.setItem("token", idToken);

    window.location.href = "/home";
  }, []);

  return (
    <div className="container mx-auto p-5 -m-12 mb-12 relative">
      <div className="h-full w-full flex flex-col items-center text-primary">
        <CircularProgress color="inherit" />
      </div>
    </div>
  );
}
