import { useForm } from "react-hook-form";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { InputElement } from "../components/FormElements/InputElement";
import { deleteSignInCookie } from "../utils/cookies";

type ForgotPasswordFieldsType = {
  email: string;
};

export function ForgotPassword() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ForgotPasswordFieldsType>();

  const onSubmit = (data: ForgotPasswordFieldsType) => {
    deleteSignInCookie({ name: "CityverseSignIn" });
    navigate("/password-reset", { state: { email: data.email } });

    // Waiting for back-end to complete
  };

  return (
    <div className="max-w-xl mx-auto my-4 px-4 md:px-0 relative h-[calc(100vh-256px)] flex flex-col justify-center z-10">
      <h1 className="text-white mt-8 mb-4 text-center text-2xl font-medium leading-9 tracking-tight font-montserrat">
        Forgot Password?
      </h1>
      {state?.tooManyAttempts && (
        <div className="font-montserrat text-center mt-6">
          <p className="text-white mb-4">
            Too many log-in attempts. Please reset your password below.
          </p>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputElement
          id="Email"
          name="email"
          type="email"
          label="Email Address"
          register={register}
          rules={{
            required: "Email is required.",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address.",
            },
          }}
          error={errors.email}
        />
        <button className="mt-6 bg-gradient-to-r from-[#00FFFF] from-8.42% to-[#6C00FA] to-103.18% rounded-[38px] py-3 w-full text-black font-montserrat font-semibold">
          Send reset email
        </button>
      </form>
      <Link
        to={"/sign-in"}
        onClick={() => deleteSignInCookie({ name: "CityverseSignIn" })}
        className="text-center text-white mt-7 font-medium font-montserrat hover:text-primary"
      >
        Back to sign in
      </Link>
    </div>
  );
}
