import { InputHTMLAttributes } from "react";
import { UseFormRegister, FieldError, FieldValues } from "react-hook-form";

interface CheckboxElementProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelClasses?: string;
  register: UseFormRegister<any>;
  rules: FieldValues;
  error?: FieldError;
}

export function CheckboxElement({
  label,
  labelClasses,
  register,
  rules,
  error,
  ...props
}: CheckboxElementProps) {
  const { name, id } = props;

  return (
    <div className="c-checkbox mt-6">
      <input {...props} {...register(name, rules)} className="hidden" />
      {label && (
        <label
          htmlFor={id}
          className={`flex items-center cursor-pointer text-white text-sm font-medium font-montserrat leading-6 ${labelClasses}`}
        >
          {label}
        </label>
      )}
      {error && (
        <p className="text-white text-xs font-montserrat mt-1 italic">
          {error.message}
        </p>
      )}
    </div>
  );
}
