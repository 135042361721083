export function CtiyverseLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="311"
      height="64"
      viewBox="0 0 311 64"
      fill="none"
    >
      <g clipPath="url(#clip0_120_6353)">
        <path
          d="M15.5899 44.8215C14.2918 43.1586 13.2386 41.3006 12.4874 39.292H63.4251C63.768 37.8202 64.0089 36.3077 64.1436 34.7668H11.3526C11.2342 33.8601 11.1689 32.9372 11.1689 32.002C11.1689 31.0669 11.2301 30.1399 11.3526 29.2373H64.1395C64.0089 27.6963 63.768 26.1839 63.4211 24.712H12.4874C13.2386 22.7035 14.2918 20.8414 15.5899 19.1825H61.5759C56.6119 7.88768 45.2961 0 32.131 0C14.3857 0 0 14.3279 0 32.002C0 49.6761 14.3857 64.0041 32.131 64.0041C45.2961 64.0041 56.6119 56.1164 61.5759 44.8256H15.5899V44.8215ZM32.131 11.1281C36.45 11.1281 40.4587 12.4292 43.7938 14.6573H20.4641C23.7992 12.4292 27.812 11.1281 32.1269 11.1281H32.131ZM32.131 52.8759C27.812 52.8759 23.8033 51.5749 20.4682 49.3468H43.7979C40.4628 51.5749 36.45 52.8759 32.1351 52.8759H32.131Z"
          fill="url(#paint0_linear_120_6353)"
        ></path>
        <path
          d="M94.2748 24.8461C95.3035 23.8378 96.5118 23.0612 97.9079 22.5083C99.304 21.9594 100.843 21.6829 102.529 21.6829C104.215 21.6829 105.778 21.996 107.219 22.6221C108.66 23.2482 109.963 24.1875 111.13 25.4397L114.992 21.8211C113.49 20.0484 111.649 18.7149 109.473 17.8123C107.297 16.9137 104.888 16.4624 102.251 16.4624C99.7694 16.4624 97.4834 16.869 95.3974 17.6781C93.3114 18.4872 91.4866 19.6337 89.9231 21.1137C88.3596 22.5937 87.1472 24.342 86.29 26.3586C85.4327 28.3752 85 30.5748 85 32.9534C85 35.3319 85.4286 37.5355 86.29 39.5481C87.1472 41.5647 88.3515 43.313 89.8986 44.793C91.4458 46.273 93.2705 47.4195 95.3729 48.2286C97.4711 49.0377 99.749 49.4443 102.202 49.4443C104.868 49.4443 107.293 48.9849 109.469 48.0701C111.645 47.1552 113.486 45.8257 114.988 44.0856L111.126 40.4223C109.959 41.703 108.656 42.6585 107.215 43.2846C105.774 43.9107 104.211 44.2238 102.525 44.2238C100.839 44.2238 99.2959 43.9473 97.9038 43.3984C96.5077 42.8495 95.2953 42.0689 94.2707 41.0606C93.242 40.0523 92.4459 38.861 91.8785 37.4867C91.3111 36.1125 91.0294 34.6 91.0294 32.9534C91.0294 31.3067 91.3111 29.7942 91.8785 28.42C92.4459 27.0457 93.242 25.8544 94.2707 24.8461H94.2748Z"
          fill="#ffffff"
        ></path>
        <path
          d="M123.087 13.6245C122.014 13.6245 121.132 13.9538 120.442 14.6084C119.752 15.2671 119.409 16.0803 119.409 17.0601C119.409 18.04 119.752 18.8166 120.442 19.4874C121.132 20.1583 122.014 20.4957 123.087 20.4957C124.161 20.4957 125.079 20.1542 125.753 19.4671C126.426 18.78 126.765 17.9343 126.765 16.926C126.765 15.9786 126.418 15.1939 125.728 14.5678C125.039 13.9416 124.157 13.6286 123.083 13.6286L123.087 13.6245Z"
          fill="#ffffff"
        ></path>
        <path
          d="M125.936 24.5249H120.188V48.9848H125.936V24.5249Z"
          fill="#ffffff"
        ></path>
        <path
          d="M146.633 43.5814C145.776 44.2522 144.703 44.5897 143.413 44.5897C142.339 44.5897 141.502 44.2685 140.906 43.6261C140.306 42.9837 140.008 42.0851 140.008 40.9223V29.286H146.584V24.7038H140.008V19.1133H134.26V24.7038H130.215V29.286H134.26V41.0606C134.26 43.7806 134.995 45.8338 136.469 47.2203C137.938 48.6108 140.024 49.306 142.723 49.306C143.764 49.306 144.776 49.1678 145.76 48.8954C146.74 48.6189 147.568 48.2083 148.242 47.6594L146.633 43.5814Z"
          fill="#ffffff"
        ></path>
        <path
          d="M188.238 42.6584L180.625 24.5249H169.999L162.223 42.6218L154.499 24.5249H148.519L159.255 49.0621L158.822 50.0379C158.271 51.2576 157.671 52.1277 157.03 52.6481C156.385 53.1686 155.544 53.4288 154.499 53.4288C153.732 53.4288 152.981 53.2743 152.246 52.9693C151.511 52.6644 150.85 52.2497 150.27 51.7333L147.972 55.9496C148.768 56.653 149.764 57.2019 150.96 57.6003C152.156 57.9988 153.352 58.1939 154.548 58.1939C155.928 58.1939 157.193 57.9784 158.34 57.5515C159.492 57.1246 160.533 56.3765 161.467 55.3072C162.402 54.2379 163.251 52.7864 164.019 50.9568L175.077 25.5373L185.177 48.9889H191.108L201.64 24.529H196.121L188.234 42.6625L188.238 42.6584Z"
          fill="#ffffff"
        ></path>
        <path
          d="M221.603 25.8096C219.733 24.7729 217.602 24.2524 215.21 24.2524C212.818 24.2524 210.573 24.7851 208.658 25.8544C206.744 26.9237 205.237 28.3955 204.151 30.2739C203.061 32.1523 202.519 34.3113 202.519 36.7548C202.519 39.1984 203.078 41.3207 204.196 43.2154C205.315 45.1101 206.895 46.5982 208.932 47.6797C210.969 48.7652 213.353 49.306 216.084 49.306C218.231 49.306 220.137 48.9604 221.811 48.2733C223.481 47.5862 224.869 46.6022 225.975 45.3174L222.893 41.7883C222.036 42.7031 221.052 43.378 219.95 43.8049C218.847 44.2319 217.602 44.4473 216.222 44.4473C214.626 44.4473 213.218 44.1424 211.989 43.5325C210.765 42.9227 209.813 42.0526 209.136 40.9223C208.711 40.2108 208.421 39.4301 208.262 38.5844H227.534C227.563 38.308 227.587 38.0193 227.604 37.7144C227.62 37.4094 227.628 37.1329 227.628 36.889C227.628 34.3235 227.093 32.0954 226.02 30.2007C224.946 28.3061 223.472 26.8424 221.603 25.8015V25.8096ZM209.026 32.2906C209.622 31.209 210.45 30.3593 211.508 29.7494C212.565 29.1395 213.798 28.8346 215.21 28.8346C216.623 28.8346 217.81 29.1395 218.868 29.7494C219.925 30.3593 220.762 31.2009 221.374 32.2702C221.807 33.0265 222.076 33.8681 222.187 34.791H208.217C208.348 33.8722 208.613 33.0387 209.022 32.2946L209.026 32.2906Z"
          fill="#ffffff"
        ></path>
        <path
          d="M238.622 28.107V24.525H233.147V48.985H238.895V37.1209C238.895 34.6164 239.54 32.7298 240.826 31.4654C242.116 30.1968 243.847 29.5626 246.023 29.5626C246.235 29.5626 246.451 29.5707 246.668 29.5869C246.88 29.6032 247.113 29.6398 247.358 29.7008V24.2485C244.998 24.2485 243.026 24.6754 241.447 25.5293C240.267 26.1676 239.328 27.0255 238.618 28.1029L238.622 28.107Z"
          fill="#ffffff"
        ></path>
        <path
          d="M268.168 36.4133C267.262 35.9417 266.274 35.5676 265.2 35.2911C264.126 35.0147 263.045 34.8114 261.959 34.6731C260.869 34.5349 259.881 34.3601 258.991 34.1446C258.101 33.9331 257.383 33.6404 256.832 33.2745C256.281 32.9086 256.003 32.3597 256.003 31.6238C256.003 30.8309 256.423 30.1723 257.268 29.6559C258.113 29.1355 259.407 28.8753 261.155 28.8753C262.318 28.8753 263.494 29.0054 264.673 29.2656C265.853 29.5258 267.025 30.0056 268.192 30.709L270.401 26.3585C269.299 25.6877 267.903 25.1672 266.217 24.8013C264.531 24.4354 262.861 24.2524 261.204 24.2524C258.995 24.2524 257.089 24.5655 255.476 25.1916C253.868 25.8178 252.619 26.7041 251.729 27.8466C250.839 28.9932 250.394 30.343 250.394 31.9002C250.394 33.307 250.672 34.4373 251.223 35.2911C251.774 36.145 252.496 36.828 253.386 37.3281C254.276 37.8323 255.264 38.2144 256.354 38.4747C257.444 38.7349 258.53 38.9422 259.62 39.0927C260.71 39.2472 261.698 39.4139 262.587 39.5968C263.477 39.7798 264.196 40.0481 264.747 40.3978C265.298 40.7475 265.576 41.2597 265.576 41.9306C265.576 42.756 265.167 43.4106 264.355 43.9025C263.543 44.3904 262.232 44.6344 260.424 44.6344C258.889 44.6344 257.358 44.4067 255.823 43.9472C254.288 43.4878 252.97 42.9105 251.868 42.2071L249.659 46.5575C250.761 47.3219 252.26 47.9683 254.146 48.505C256.031 49.0377 257.999 49.306 260.057 49.306C262.355 49.306 264.335 48.9929 265.988 48.3668C267.645 47.7407 268.923 46.8624 269.829 45.7321C270.731 44.6018 271.185 43.2723 271.185 41.7476C271.185 40.3734 270.907 39.2594 270.356 38.4055C269.805 37.5517 269.074 36.8849 268.172 36.4133H268.168Z"
          fill="#ffffff"
        ></path>
        <path
          d="M293.212 25.8096C291.343 24.7729 289.212 24.2524 286.819 24.2524C284.427 24.2524 282.182 24.7851 280.268 25.8544C278.353 26.9237 276.847 28.3955 275.761 30.2739C274.671 32.1523 274.128 34.3113 274.128 36.7548C274.128 39.1984 274.687 41.3207 275.806 43.2154C276.924 45.1101 278.504 46.5982 280.541 47.6797C282.578 48.7652 284.962 49.306 287.693 49.306C289.84 49.306 291.747 48.9604 293.42 48.2733C295.09 47.5862 296.478 46.6022 297.584 45.3174L294.502 41.7883C293.645 42.7031 292.661 43.378 291.559 43.8049C290.457 44.2319 289.212 44.4473 287.832 44.4473C286.236 44.4473 284.827 44.1424 283.599 43.5325C282.374 42.9227 281.423 42.0526 280.745 40.9223C280.321 40.2108 280.031 39.4301 279.872 38.5844H299.144C299.172 38.308 299.197 38.0193 299.213 37.7144C299.229 37.4094 299.238 37.1329 299.238 36.889C299.238 34.3235 298.703 32.0954 297.629 30.2007C296.556 28.3061 295.082 26.8424 293.212 25.8015V25.8096ZM280.635 32.2906C281.231 31.209 282.06 30.3593 283.117 29.7494C284.174 29.1395 285.407 28.8346 286.819 28.8346C288.232 28.8346 289.42 29.1395 290.477 29.7494C291.534 30.3593 292.371 31.2009 292.984 32.2702C293.416 33.0265 293.686 33.8681 293.796 34.791H279.831C279.961 33.8722 280.227 33.0387 280.635 32.2946V32.2906Z"
          fill="#ffffff"
        ></path>
        <path
          d="M309.264 42.7358C308.529 42.0487 307.639 41.7031 306.598 41.7031C305.557 41.7031 304.667 42.0487 303.928 42.7358C303.194 43.423 302.826 44.3459 302.826 45.5087C302.826 46.6065 303.194 47.5172 303.928 48.2328C304.663 48.9525 305.553 49.3103 306.598 49.3103C307.643 49.3103 308.529 48.9525 309.264 48.2328C309.999 47.5172 310.366 46.6065 310.366 45.5087C310.366 44.35 309.999 43.423 309.264 42.7358Z"
          fill="#ffffff"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_120_6353"
          x1="0"
          y1="31.998"
          x2="64.1395"
          y2="31.998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C8FF00"></stop>
          <stop offset="1" stopColor="#00FFFF"></stop>
        </linearGradient>
        <clipPath id="clip0_120_6353">
          <rect width="310.366" height="64" fill="#ffffff"></rect>
        </clipPath>
      </defs>
    </svg>
  );
}
