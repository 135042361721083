import { InputHTMLAttributes } from "react";
import { UseFormRegister, FieldError, FieldValues } from "react-hook-form";

interface InputElementProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelClasses?: string;
  register: UseFormRegister<any>;
  rules: FieldValues;
  error?: FieldError;
}

export function InputElement({
  label,
  labelClasses,
  register,
  rules,
  error,
  ...props
}: InputElementProps) {
  const { name, id } = props;

  return (
    <div className="mt-6">
      {label && (
        <label
          htmlFor={id}
          className={`block text-primary text-sm font-medium font-montserrat leading-6 ${labelClasses}`}
        >
          {label}
        </label>
      )}
      <input
        {...props}
        {...register(name, rules)}
        className="mt-2 mb-1 bg-transparent active:bg-transparent focus:bg-transparent block w-full rounded-xl	border-2 border-primary px-4 py-2 font-montserrat text-lg text-white placeholder:text-white sm:text-sm sm:leading-6"
      />
      {error && (
        <p className="text-white text-xs font-montserrat mt-1 italic">
          {error.message}
        </p>
      )}
    </div>
  );
}
