import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import "../public/styles.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { Authenticate } from "./Authenticate";
import { Login } from "./pages/Login";
import { SignUp } from "./pages/SignUp";
import { SignIn } from "./pages/SignIn";
import { ForgotPassword } from "./pages/ForgotPassword";
import { ResetPrompt } from "./pages/ResetPrompt";

const queryClient = new QueryClient();

type AuthRootProps = {
  queryClient: QueryClient;
};

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Authenticate />,
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "sign-up",
      element: <SignUp />,
    },
    {
      path: "sign-in",
      element: <SignIn />,
    },
    {
      path: "forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "password-reset",
      element: <ResetPrompt />,
    },
  ],
  {
    basename: "/auth",
  }
);

function RootWithProviders() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: RootWithProviders,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    console.error(err, info);
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
