import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { queryRequest } from "../utils/request";
import { Link, useNavigate } from "react-router-dom";
import {
  PasswordElement,
  SelectElement,
  InputElement,
} from "../components/FormElements";
import { CtiyverseLogo } from "../components/CityverseLogo";
import { ErrorIcon } from "../components/ErrorIcon";
import CircularProgress from "@mui/material/CircularProgress";

type SignUpFieldsType = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  tenant: string;
};

export function SignUp() {
  const navigate = useNavigate();
  const [responseError, setResponseError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userExists, setUserExists] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<SignUpFieldsType>();

  const registerUser = useMutation(
    (data: SignUpFieldsType) =>
      queryRequest({
        path: "auth/register",
        body: data,
        method: "POST",
        authenticated: false,
      }),
    {
      onSuccess: (response) => {
        // Note: This would re-direct to the "Stack" when completed
        navigate("/");
        setIsLoading(false);
      },
      onError: (error) => {
        setIsLoading(false);

        if (
          error &&
          error ===
            "Another object with the same value for property userPrincipalName already exists."
        ) {
          setUserExists(true);
        } else {
          setResponseError(error);
        }
      },
    }
  );

  const onSubmit = (data: SignUpFieldsType) => {
    setIsLoading(true);
    registerUser.mutate(data);
  };

  // Temp data
  const locationOptions = [
    { value: "stpete", label: "St. Petersburg" },
    { value: "tampa", label: "Tampa" },
  ];

  return (
    <div className="max-w-xl mx-auto my-12 md:my-4 px-4 md:px-0 relative md:h-[calc(100vh-256px)] flex flex-col justify-center z-10">
      <div className="flex justify-center">
        <CtiyverseLogo />
      </div>
      <h1 className="text-white mt-8 mb-4 text-center text-2xl font-bold leading-9 tracking-tight font-montserrat">
        Sign up for an account
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className="relative">
        {isLoading && (
          <div className="absolute -right-8 -left-8 -bottom-8 -top-4 rounded-xl flex items-center justify-center md:bg-black/20">
            <CircularProgress
              size="18rem"
              sx={{
                color: "#00FFFF",
              }}
            />
          </div>
        )}
        <div className="grid md:grid-cols-2 gap-x-4">
          <InputElement
            id="First Name"
            name="firstName"
            type="text"
            label="First Name"
            register={register}
            rules={{ required: "First name is required." }}
            error={errors.firstName}
          />
          <InputElement
            id="Last Name"
            name="lastName"
            type="text"
            label="Last Name"
            register={register}
            rules={{ required: "Last name is required." }}
            error={errors.lastName}
          />
        </div>
        <InputElement
          id="Email"
          name="email"
          type="email"
          label="Email Address"
          register={register}
          rules={{
            required: "Email is required.",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address.",
            },
          }}
          error={errors.email}
        />
        <PasswordElement
          id="Password"
          name="password"
          type="password"
          label="Password"
          register={register}
          rules={{
            required: "Password is required.",
            minLength: {
              value: 8,
              message: "Password must be at least 8 characters long.",
            },
          }}
          error={errors.password}
        />
        <SelectElement
          options={locationOptions}
          id="Location"
          name="tenant"
          label="Location"
          register={register}
          rules={{ required: "Location is required." }}
          error={errors.tenant}
        />
        {responseError && (
          <p className="text-white text-xs font-montserrat mt-5 flex">
            <ErrorIcon addClassName={"text-white w-7 mr-2"} />
            {responseError.message}
          </p>
        )}
        <button className="mt-6 bg-gradient-to-r from-[#00FFFF] from-8.42% to-[#6C00FA] to-103.18% rounded-[38px] py-3 w-full text-black font-montserrat font-semibold">
          Sign Up
        </button>
      </form>
      {userExists && (
        <div className="font-montserrat text-center mt-6">
          <p className="text-white mb-4">
            Email exists already, would you like to sign in?
          </p>
          <Link
            to={"/sign-in"}
            className="text-white mt-7 font-medium hover:text-primary"
          >
            Back to sign in
          </Link>
        </div>
      )}
    </div>
  );
}
