export function setSignInCookie({
  name,
  value,
  minutes,
}: {
  name: string;
  value: number | string;
  minutes: number;
}) {
  const date = new Date();
  date.setTime(date.getTime() + minutes * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}

export function getSignInCookie({ name }: { name: string }) {
  let cookie = {};
  document.cookie.split(";").forEach((element) => {
    let [k, v] = element.split("=");
    cookie[k.trim()] = v;
  });
  return cookie[name];
}

export function deleteSignInCookie({ name }: { name: string }) {
  document.cookie = `${name}=;expires${new Date(0).toUTCString()};path=/`;
}
