export async function fetchRequest({
  path,
  body,
  params,
  method,
  config = {},
  authenticated = false,
  service = "identity",
}: {
  path: string;
  body?: any;
  method?: string;
  params?: string;
  config?: RequestInit;
  authenticated?: boolean;
  service?: string;
}) {
  const apiUrl = `https://${service}.ashybeach-de0b57bf.eastus.azurecontainerapps.io/api_v1`;
  const headers: Record<string, string> = {};
  const token = window.localStorage.getItem("token") || "";

  if (authenticated) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  if (body) {
    config.body = typeof body === "string" ? body : JSON.stringify(body);
  }

  const customConfig = {
    method: method ?? "GET",
    ...config,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
      ...config.headers,
    },
  };

  return fetch(`${apiUrl}/${path}${params ?? ""}`, customConfig);
}

export async function queryRequest({
  path,
  body,
  params,
  method,
  config,
  authenticated,
  service,
}: {
  path: string;
  body?: any;
  method?: string;
  params?: string;
  config?: RequestInit;
  authenticated?: boolean;
  service?: string;
}) {
  const response = await fetchRequest({
    path,
    body,
    params,
    method,
    config,
    authenticated,
    service,
  });

  const token = window.localStorage.getItem("token") || "";

  if (!response.ok) {
    // handle faulty responses
    const errorResponse = await response.json();
    if (response.status === 401 && token) {
      window.localStorage.removeItem("token");
      // redirect to login url
      window.location.href = "/auth/login";
    } else {
      throw errorResponse;
    }
  }

  return response.json();
}
