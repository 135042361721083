import { useState, InputHTMLAttributes } from "react";
import { UseFormRegister, FieldError, FieldValues } from "react-hook-form";
import { Link } from "react-router-dom";
import { EyeIconHide } from "../EyeIcon/EyeIconHide";
import { EyeIconShow } from "../EyeIcon/EyeIconShow";

interface PasswordElementProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelClasses?: string;
  forgot?: Boolean;
  register: UseFormRegister<any>;
  rules: FieldValues;
  error?: FieldError;
}

export function PasswordElement({
  label,
  labelClasses,
  forgot,
  register,
  rules,
  error,
  ...props
}: PasswordElementProps) {
  const { name, id } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <div className="mt-6">
      <div className="flex justify-between">
        {label && (
          <label
            htmlFor={id}
            className={`block text-primary text-sm font-medium font-montserrat leading-6 ${labelClasses}`}
          >
            {label}
          </label>
        )}
        {forgot && (
          <Link
            to={"/forgot-password/"}
            className="text-white text-sm font-medium font-montserrat leading-6"
          >
            Forgot?
          </Link>
        )}
      </div>
      <div className="flex items-center justify-between mt-2 mb-1 bg-transparent active:bg-transparent focus:bg-transparent w-full rounded-xl	border-2 border-primary px-4 py-2 font-montserrat text-lg text-white placeholder:text-white sm:text-sm sm:leading-6">
        <input
          {...props}
          {...register(name, rules)}
          type={showPassword ? "text" : "password"}
          className="bg-transparent w-full focus:border-transparent focus:ring-0 !outline-none"
        />
        <button onClick={(event) => handleTogglePassword(event)}>
          {showPassword ? <EyeIconHide /> : <EyeIconShow />}
        </button>
      </div>
      {error && (
        <p className="text-white text-xs font-montserrat mt-1 italic">
          {error.message}
        </p>
      )}
    </div>
  );
}
