import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { queryRequest } from "../utils/request";
import { useNavigate } from "react-router-dom";
import { InputElement } from "../components/FormElements/InputElement";
import { PasswordElement } from "../components/FormElements/PasswordElement";
import { CheckboxElement } from "../components/FormElements/CheckboxElement";
import { setSignInCookie, getSignInCookie } from "../utils/cookies";
import { ErrorIcon } from "../components/ErrorIcon";
import CircularProgress from "@mui/material/CircularProgress";

type SignInFieldsType = {
  username: string;
  password: string;
  signedIn: string;
};

export function SignIn() {
  const navigate = useNavigate();
  const [responseError, setResponseError] = useState(false);
  const [signInAttempts, setSignInAttempts] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<SignInFieldsType>();

  const signInUser = useMutation(
    (data: SignInFieldsType) =>
      queryRequest({
        path: "auth/login",
        body: data,
        method: "POST",
        authenticated: false,
      }),
    {
      onSuccess: (response) => {
        // Note: This would re-direct to the "Stack" when completed
        // Not sure how we are handling authentication on that app but I set the access_token in localStorate Below
        // navigate("/");

        localStorage.setItem("access_token", response?.data?.access_token);
        setIsLoading(false);
      },
      onError: (error) => {
        setSignInAttempts(signInAttempts + 1);
        setResponseError(true);
        setIsLoading(false);
      },
    }
  );

  const onSubmit = (data: SignInFieldsType) => {
    setIsLoading(true);
    signInUser.mutate(data);
  };

  useEffect(() => {
    const signInCookie = getSignInCookie({ name: "CityverseSignIn" });
    if (signInCookie) {
      setSignInAttempts(parseInt(signInCookie));
    }
  }, []);

  useEffect(() => {
    if (signInAttempts === 1) {
      setSignInCookie({ name: "CityverseSignIn", value: 1, minutes: 15 });
    } else {
      setSignInCookie({
        name: "CityverseSignIn",
        value: signInAttempts,
        minutes: 15,
      });
    }

    if (signInAttempts > 3) {
      navigate("/forgot-password/", { state: { tooManyAttempts: true } });
    }
  }, [signInAttempts]);

  return (
    <div className="max-w-xl mx-auto my-4 px-4 md:px-0 relative h-[calc(100vh-256px)] flex flex-col justify-center z-10">
      <form onSubmit={handleSubmit(onSubmit)} className="relative">
        {isLoading && (
          <div className="absolute -right-8 -left-8 -bottom-8 -top-4 rounded-xl flex items-center justify-center md:bg-black/20">
            <CircularProgress
              size="18rem"
              sx={{
                color: "#00FFFF",
              }}
            />
          </div>
        )}
        <InputElement
          id="Email"
          name="username"
          type="email"
          label="Email Address"
          register={register}
          rules={{
            required: "Email is required.",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address.",
            },
          }}
          error={errors.username}
        />
        <PasswordElement
          id="Password"
          name="password"
          type="password"
          label="Password"
          forgot={true}
          register={register}
          rules={{
            required: "Password is required.",
            minLength: {
              value: 8,
              message: "Password must be at least 8 characters long.",
            },
          }}
          error={errors.password}
        />
        <CheckboxElement
          id="SignedIn"
          name="signedIn"
          type="checkbox"
          label="Keep me signed in"
          register={register}
          rules={[]}
        />
        {responseError && (
          <>
            <p className="text-white text-xs font-montserrat mt-5 flex items-center">
              <ErrorIcon addClassName="text-white w-6 mr-2" />
              Incorrect Username or Password. Please try again.
            </p>
            <p className="text-white text-xs font-montserrat mt-5 flex items-center">
              <ErrorIcon addClassName="text-white w-6 mr-2" />
              Login Attempts Remaining: {3 - signInAttempts}
            </p>
          </>
        )}
        <button className="mt-6 bg-gradient-to-r from-[#00FFFF] from-8.42% to-[#6C00FA] to-103.18% rounded-[38px] py-3 w-full text-black font-montserrat font-semibold">
          Sign In
        </button>
      </form>
    </div>
  );
}
