import { CircularProgress } from "@mui/material";

const stagingLoginUrl =
  "https://cityverseid.b2clogin.com/cityverseid.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_Cityverse_Signin&client_id=99b98eae-e343-44ae-b4fa-36a6ca6712b8&nonce=defaultNonce&scope=openid&response_type=id_token&prompt=login";

export function Login() {
  // this will be dynamic but is erroring right now for localhost
  const redirectUri = encodeURIComponent(`${window.location.origin}/auth`);

  window.location.href = `${stagingLoginUrl}&redirect_uri=${redirectUri}`;

  return (
    <div className="container mx-auto p-5 -m-12 mb-12 relative">
      <div className="h-full w-full flex flex-col items-center text-primary">
        <CircularProgress color="inherit" />
      </div>
    </div>
  );
}
